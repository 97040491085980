.projectGrid {
	@include flex-grid-row();

	margin-left: 300px;
	max-width: 100%;
	width: calc(100% - 300px);

	@include breakpoint(xlarge only) {
		margin-left: 200px;
		max-width: 100%;
		width: calc(100% - 200px);
	}

	@include breakpoint(large only) {
		margin-left: 200px;
		max-width: 100%;
		width: calc(100% - 200px);
	}

	@include breakpoint(medium only) {
		margin-left: 200px;
		max-width: 100%;
		width: calc(100% - 200px);
	}

	@include breakpoint(small only) {
		margin-left: 0px;
		max-width: 100%;
		width: calc(100%);
	}

	.projectGrid__project {
		@include flex-grid-column(3);

		@include breakpoint(xlarge only) {
			@include flex-grid-column(4);
		}

		@include breakpoint(large only) {
			@include flex-grid-column(4);
		}

		@include breakpoint(medium only) {
			@include flex-grid-column(6);
		}

		@include breakpoint(small only) {
			@include flex-grid-column(12);
		}

		.projectGrid__project--image {
			height: 225px;
			overflow: hidden;
			position: relative;
			text-align: center;
			width: 100%;

			@include breakpoint(xlarge only) {
				height: 225px;
			}

			@include breakpoint(large only) {
				height: 220px;
			}

			@include breakpoint(medium only) {
				height: 180px;
			}

			@include breakpoint(small only) {
				height: 150px;
			}

			img {
				display: block;
				min-height: 100%;
				margin: auto;
				position: absolute;
				max-width: none !important;
				min-width: 100%;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);

				width: auto;
				height: auto;
			}
		}
		.projectGrid__project--meta {
			font-family: 'cantarellbold', Arial;
			font-size: rem-calc(12);
			padding: 10px 0;
			text-transform: uppercase;
		}
	}
}

.projectImages {
	// border: red 1px solid;
	display: block;
	height: 100%;
	left: 0px;
	padding-left: 300px;
	position: relative;
	white-space: nowrap;
	width: 100000px;

	@include breakpoint(xlarge only) {
		padding-left: 200px;
	}

	@include breakpoint(large only) {
		padding-left: 200px;
	}

	@include breakpoint(medium only) {
		padding-left: 200px;
	}

	@include breakpoint(small only) {
		margin: 0px 15px;
		padding-left: 0px;
		white-space: normal;
		width: calc(100% - 30px);
	}

	.projectImages__description {
		color: #aaaaaa;
		display: block;
		font-family: 'Lato', sans-serif;
		font-size: rem-calc(12);
		left: 0;
		padding: 30px 0;
		width: 100%;

		h1, h2 {
			color: #555;
			font-family: 'cantarellbold', Arial;
			font-size: rem-calc(14) !important;
			margin: 0;
			margin-bottom: 10px;
			padding: 0;
			text-transform: uppercase;
		}
	}

	nav {
		// border: red 1px solid;
		left: 300px;
		position: fixed;
		top: 50%;
		width: 1500px;
		z-index: 1;

		@include breakpoint(xlarge only) {
			left: 200px;
		}

		@include breakpoint(large only) {
			left: 200px;
		}

		@include breakpoint(medium only) {

		}


		@include breakpoint(small only) {
			display: none;
		}

		a {
			background: rgba(0,0,0,0.5);
			color: #fff;
			display: inline-block;
			padding: 10px 15px;

			.fa {
				color: #fff;
				font-size: rem-calc(28);
			}
		}
		a.next {
			float: right;
		}
		a.prev {
			float: left;
		}
		a.disabled {
			visibility: hidden;
		}
	}

	.projectImages__image {
		// border: red 1px solid;
		display: inline-block;
		float: left;
		height: 100%;

		@include breakpoint(xlarge only) {
			max-width: 100% !important;
		}

		@include breakpoint(large only) {
			max-width: 100%;
		}

		@include breakpoint(medium only) {
			max-width: 100% !important;
		}

		@include breakpoint(small only) {
			display: block;
			float: none;
			height: auto;
			margin-bottom: 15px;
			width: 100%;
		}

		img {
			height: 100% !important;
			max-height: 100%;
			margin-right: 15px;
			width: auto !important;
			max-width: none !important;

			@include breakpoint(small only) {
				height: auto !important;
				max-height: auto;
				margin-right: 0;
				width: 100% !important;
				max-width: none !important;
			}
		}
	}
}
