section.home {
	bottom: 20px;
	left: 15px;
	overflow: hidden;
	position: absolute;
	right: 15px;
	top: 75px;
	width: calc(100% - 30px);
	max-width: calc(100% - 30px);

	.orbit {
		bottom: 0px;
		left: 0px;
		position: absolute;
		right: 0px;
		top: 0px;

		ul {
			li {
				div {
					// border: green 2px solid;
					background-position: center center;
					background-size: cover;
					display: block;

					height: 100%;
					/* min-height: 1000px; */
					width: 100%;

				}
			}
		}
	}
}

section.panel {
	background: rgba(255,255,255,0.9);
	bottom: 20px;
	color: #aaaaaa;
	display: block;
	// font-family: 'cantarellregular', Arial;
	font-family: 'Lato', sans-serif;
	font-size: rem-calc(12);
	// height: 100%;
	left: 0;
	padding: 0 25px;
	padding-top: 100px;
	position: fixed;
	top: 75px;
	width: 300px;
	z-index: 1;

	@include breakpoint(xlarge only) {
		padding-top: 50px;
		width: 200px;
	}

	@include breakpoint(large only) {
		padding-top: 50px;
		width: 200px;
	}

	@include breakpoint(medium only) {
		padding-top: 25px;
		width: 200px;
	}

	@include breakpoint(small only) {
		display: none;
		/* bottom: auto;
		position: relative;
		top: auto;
		width: 100%; */
	}

	a {
		// border-bottom: #cccccc 1px dotted;

		&:hover {
			// border-bottom: none;
		}
	}

	h1, h2 {
		color: #555;
		font-family: 'cantarellbold', Arial;
		font-size: rem-calc(14) !important;
		margin: 0;
		margin-bottom: 10px;
		padding: 0;
		text-transform: uppercase;
	}

	ul {
		display: none;
		// margin-left: 5px;
		margin: 0;
		padding-left: 0;

		@include breakpoint(xlarge only) {
			display: none;
		}

		@include breakpoint(large only) {
			display: none;
		}

		@include breakpoint(medium only) {
			display: none;
		}

		li {
			display: block;
			list-style: none;
			margin-bottom: 0px;

			a {
				color: #999999;
				// font-family: 'Lato', sans-serif;
				font-family: 'cantarellregular', Arial;
				text-transform: uppercase;

				&.active {
					border-bottom: 0 none;
					color: #333;
					font-weight: bold;
				}
			}
		}
	}

	.panel__description {
		bottom: 0px;
		padding-right: 30px;
		position: absolute;
		// text-align: justify;

		@include breakpoint(xlarge only) {
			max-height: 50%;
			overflow: auto;
		}

		@include breakpoint(large only) {
			max-height: 50%;
			overflow: auto;
		}

		@include breakpoint(medium only) {
			max-height: 50%;
			overflow: auto;
		}

		.panel__description--link {
			color: #cccccc;
			margin-top: 25px;
		}
	}
}
section.content {
	bottom: 20px;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 75px;
	width: 100%;
	max-width: 100%;

	&.project {
		overflow: auto;
	}

	@include breakpoint(small only) {
		bottom: auto;
		position: relative;
		top: 75px;
	}

	.pageContent {
		margin-left: 315px;
		max-width: 800px;

		@include breakpoint(xlarge only) {
			margin-left: 215px;
			max-width: calc(90% - 215px);
		}

		@include breakpoint(large only) {
			margin-left: 215px;
			max-width: calc(90% - 215px);
		}

		@include breakpoint(medium only) {
			margin-left: 215px;
			max-width: calc(90% - 215px);
		}

		@include breakpoint(small only) {
			margin-left: 0px;
			padding: 15px;
			max-width: calc(100% - 30px);
		}

		a {
			border-bottom: #cccccc 1px dotted;

			&:hover {
				border-bottom: none;
			}
		}

		.lead {
			color: #555;
			font-family: 'cantarellbold', Arial;
			font-size: rem-calc(22) !important;
			margin: 100px 0 75px 0;

			@include breakpoint(medium only) {
				font-size: rem-calc(18) !important;
				margin: 50px 0 25px 0;
			}
		}

		.pageContent__body {
			// border: red 1px solid;

			@include breakpoint(medium up) {

				-webkit-column-count: 2; /* Chrome, Safari, Opera */
				-moz-column-count: 2; /* Firefox */
				column-count: 2;
				-webkit-column-gap: 50px; /* Chrome, Safari, Opera */
				-moz-column-gap: 50px; /* Firefox */
				column-gap: 50px;
			}

			font-family: 'Lato', sans-serif;
			font-size: rem-calc(12);

			p {
				// border: red 1px solid;
				// margin-bottom: 0px !important;
			}

			.contactPanel {
				margin-left: 25px;

				span.elegant {
					display: inline-block;
					float: left;
					margin-left: -25px;
					position: relative;
					top: 3px;
				}
			}
		}

	}
}

