/*
footer {
	bottom: 10px;
	font-family: 'Lato', sans-serif;
	left: 25px;
	position: fixed;
	right: 25px;
	font-size: rem-calc(10);
	z-index: 2;

	@include breakpoint(small only) {
		bottom: auto;
		position: relative;
	}
}

footer * {
	color: #cccccc !important;
}
*/