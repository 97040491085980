
nav.header,
.navbar-toggle {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

nav.header {
	position: fixed;
	z-index: 10;
	background-color: rgba(255,255,255, 0.95);
	width: 100%;
	height: 100%;
	text-align: center;
	display: table;
	color: white;

	&:not(.nav-hide) {
		display: table !important;
	}
	&.nav-hide {
		display: none !important;
	}
}

.nav-hide { display: none !important; }

nav.header ul {
	display: table-cell;
	vertical-align: middle;
	padding-left: 0 !important;
}

nav.header ul li { list-style: none; }

nav.header ul li a {
	font-family: 'cantarellbold', Arial;
	font-size: 35pt;
	color: #999999;
	font-weight: bolder;
	text-decoration: none;
	text-transform: uppercase;
}

.navbar-toggle {
	display: block;
	position: fixed;
	top: 25px;
	right: 20px;
	width: 25px;
	height: 25px;
	z-index: 99;
	cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
	width: 100%;
	height: 4px;
	margin-bottom: 3px;
	background-color: #777;
	transition: all 0.3s ease-in-out;
}

.navbar-on .bar1,
.navbar-on .bar2,
.navbar-on .bar3 { /*background-color: white;*/ }

.navbar-on .bar1 {
	transform-origin: 10% 40%;
	transform: rotate(45deg);
}

.navbar-on .bar3 {
	transform-origin: 10% 40%;
	transform: rotate(-45deg);
}

.navbar-on .bar2 { background-color: transparent; }

header {
	position: fixed;
	margin: 5px 0;
	width: 100%;

	@include breakpoint(small only) {
		background: rgba(255,255,255,0.9);
		margin: 0;
		padding-bottom: 5px;
		padding-top: 5px;
		z-index: 2;
	}

	.logo {
		width: 285px;

		@include breakpoint(xlarge only) {
			width: 185px;
		}

		@include breakpoint(large only) {
			width: 185px;
		}

		@include breakpoint(medium only) {
			width: 185px;
		}

		img {
			padding-left: 10px;
			height: 60px;
		}
	}

	ul.menu {
		li {
			a {
				color: #999999;
				font-family: 'cantarellregular', Arial;
				font-size: rem-calc(12);
				text-transform: uppercase;

				&.active {
					color: #333;
					font-family: 'cantarellbold', Arial;
				}

				&.social {
					font-size: rem-calc(18);
				}
			}
		}
	}
}