@import "foundation";

@include foundation-everything($flex: true);

@import 'motion-ui';

@include motion-ui-transitions;
@include motion-ui-animations;

@import "font-awesome";

body * {
	color: #767676;
}

@import "styleguide/agushi";

@import "components/elegant",
	"components/projects";

@import "structure/site/general",
	"structure/site/header",
	"structure/site/content",
	"structure/site/footer";

