body {
	overflow-x: hidden;
	overflow-y: hidden;

	&.project {
		overflow-y: scroll;
	}

	@include breakpoint(xlarge only) {
		// background: yellow;
	}

	@include breakpoint(large only) {
		// background: green;
	}

	@include breakpoint(medium only) {
		// background: red;
	}

	@include breakpoint(small only) {
		overflow-y: scroll;
	}

	.row {
		width: 100%;
		max-width: none;
	}
}

::-webkit-scrollbar {
	width: 14px;
	height: 18px;
}
::-webkit-scrollbar-thumb {
	height: 6px;
	border: 4px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
	-webkit-border-radius: 7px;
	background-color: rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-button {
	width: 0;
	height: 0;
	display: none;
}
::-webkit-scrollbar-corner {
	background-color: transparent;
}