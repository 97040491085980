@import url(https://fonts.googleapis.com/css?family=Lato:400,300);

@font-face {
	font-family: 'cantarellregular';
	src: url('/fonts/Cantarell-Regular-webfont.eot');
	src: url('/fonts/Cantarell-Regular-webfont.eot?#iefix') format('embedded-opentype'),
	url('/fonts/Cantarell-Regular-webfont.woff') format('woff'),
	url('/fonts/Cantarell-Regular-webfont.ttf') format('truetype'),
	url('/fonts/Cantarell-Regular-webfont.svg#cantarellregular') format('svg');
	font-weight: normal;
	font-style: normal;

}


@font-face {
	font-family: 'cantarellbold';
	src: url('/fonts/Cantarell-Bold-webfont.eot');
	src: url('/fonts/Cantarell-Bold-webfont.eot?#iefix') format('embedded-opentype'),
	url('/fonts/Cantarell-Bold-webfont.woff') format('woff'),
	url('/fonts/Cantarell-Bold-webfont.ttf') format('truetype'),
	url('/fonts/Cantarell-Bold-webfont.svg#cantarellbold') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'cantarelloblique';
	src: url('/fonts/Cantarell-Oblique-webfont.eot');
	src: url('/fonts/Cantarell-Oblique-webfont.eot?#iefix') format('embedded-opentype'),
	url('/fonts/Cantarell-Oblique-webfont.woff') format('woff'),
	url('/fonts/Cantarell-Oblique-webfont.ttf') format('truetype'),
	url('/fonts/Cantarell-Oblique-webfont.svg#cantarelloblique') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'cantarellboldoblique';
	src: url('/fonts/Cantarell-BoldOblique-webfont.eot');
	src: url('/fonts/Cantarell-BoldOblique-webfont.eot?#iefix') format('embedded-opentype'),
	url('/fonts/Cantarell-BoldOblique-webfont.woff') format('woff'),
	url('/fonts/Cantarell-BoldOblique-webfont.ttf') format('truetype'),
	url('/fonts/Cantarell-BoldOblique-webfont.svg#cantarellboldoblique') format('svg');
	font-weight: normal;
	font-style: normal;
}

$breakpoints: (
	small: 0px,
	medium: 700px,
	large: 950px,
	xlarge: 1100px,
	xxlarge: 1200px,
);
